import { useEffect, useState } from 'react'
import Sidebar from '../../shared/components/common/side-menu'
import { TLayoutProps } from '../../shared/models'

const BaseLayout = ({ children }: TLayoutProps) => {
  const [showSideBar, setShowSideBar] = useState<boolean>(false)

  const handlerCheckDevice = () => {
    setShowSideBar(window.innerWidth >= 768)
  }

  useEffect(() => {
    handlerCheckDevice()
    window.addEventListener('resize', handlerCheckDevice)

    return () => {
      window.removeEventListener('resize', handlerCheckDevice)
    }
  }, [])

  return (
    <div className="d-flex content w-100 h-100 ">
      {showSideBar && (
        <div>
          <Sidebar />
        </div>
      )}
      <div className="flex-grow-1  mx-1 ">{children}</div>
    </div>
  )
}

export default BaseLayout
