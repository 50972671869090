import { TOpportunityForm } from '../../../core/models'
import http from '../../../core/services/api/request'
const basePath = 'newCourse/'

export const coursesService = {
  async createNewCourse(data: TOpportunityForm): Promise<any> {
    return await http.post(`${basePath}createNewCourse`, data)
  },

  async getAllNewCourses(pagination: any): Promise<any> {
    let url = `${basePath}getAllNewCourses`
    if (pagination) {
      const params = new URLSearchParams()

      Object.keys(pagination).forEach(key => {
        params.append(key, pagination[key])
      })

      url += `?${params.toString()}`
    }
    return await http.get<unknown, any>(url)
  },

  async getNewCourseById(courseId: number): Promise<any> {
    return await http.get(`${basePath}getNewCourseById?courseId=${courseId}`)
  },

  async updateNewCourse(data: TOpportunityForm): Promise<any> {
    return await http.put(`${basePath}updateNewCourse`, data)
  },

  async activateCourse(data: {
    courseId: number
    active: boolean
  }): Promise<any> {
    return await http.put(`${basePath}activation`, data)
  }
}
