import http from '../../../core/services/api/request'
import { EIntershipStatusEnum } from '../../enums'
import {
  ECandidatesStatusEnum,
  EHowDidYouHearAboutISBETEnum
} from '../../enums/candidate'
import {
  TAddressResponse,
  TCandidateStatus,
  TKnowledgeItem,
  TLatLongResponse,
  TLevelOption,
  TNameValueOption,
  TNationality,
  TSpecificSkills,
  TStatus,
  TValueOption
} from '../../models'
const basePath: string = 'common'

export const commonService = {
  async getStatus(): Promise<TStatus[]> {
    return [
      {
        id: EIntershipStatusEnum.INSCRIPTION,
        label: 'Inscrição',
        status: false
      },
      {
        id: EIntershipStatusEnum.INTERVIEW_ISBET,
        label: 'Entrevista ISBET',
        status: false
      },
      {
        id: EIntershipStatusEnum.INTERVIEW_COMPANY,
        label: 'Entrevista Empresa',
        status: false
      },
      { id: EIntershipStatusEnum.FINISHED, label: 'Finalizado', status: false },
      { id: EIntershipStatusEnum.HIRING, label: 'Contratação', status: false },
      { id: EIntershipStatusEnum.CANCELED, label: 'Cancelado', status: false }
    ]
  },

  async getCandidateStatus(): Promise<TCandidateStatus[]> {
    return [
      {
        id: ECandidatesStatusEnum.SYSTEM,
        label: 'Sistema',
        value: 'S',
        status: true
      },
      {
        id: ECandidatesStatusEnum.PORTAL,
        label: 'Portal',
        value: 'P',
        status: true
      }
    ]
  },

  async getAddressByZipCode(zipcode: string) {
    return await http.get<unknown, TAddressResponse>(
      `https://viacep.com.br/ws/${zipcode}/json/`
    )
  },

  async getCordinatesByAddress(address: string) {
    return await http.get<unknown, TLatLongResponse>(
      `/common/getCordinatesByAddress?address=${address}`
    )
  },

  async getLatLngFromAddress(address: string) {
    return await http.post<unknown, TLatLongResponse>(
      `${basePath}/getLatLngFromAddress`,
      address
    )
  },
  async getFacilitiesFilters() {
    return await http.get<unknown, any>(`/facility/getAllFacilitiesFilter`)
  },

  async getInterviwersFilters() {
    return [
      { id: 0, name: 'Todos' },
      { id: 1, name: 'Alexandre Rosa' },
      { id: 2, name: 'Marcio Motoshima' }
    ]
    // return await http.get<unknown, any>(`/facility/getAllFacilitiesFilter`)
  },

  async getCompaniesFilters(pagination: any) {
    let url = '/company/getAllCompaniesFilter'

    if (pagination) {
      const params = new URLSearchParams()

      Object.keys(pagination).forEach(key => {
        params.append(key, pagination[key])
      })

      url += `?${params.toString()}`
    }
    return await http.get<unknown, any>(url)
  },

  async getCompanyOverdueStatus(companyId: number, facilityId: number) {
    return await http.get<unknown, any>(
      `/company/getCompanyOverdueStatus?companyId=${companyId}&facilityId=${facilityId}`
    )
  },

  async getCompaniesByFacilityId(facilityId: number) {
    return await http.get<unknown, any>(
      `/facility/getCompaniesByFacilityId?facilityId=${facilityId}`
    )
  },

  async getOpportunityAreas() {
    return await http.get<unknown, any>(`/opportunityArea/getAllOportunityArea`)
  },

  async getIndicatorsFilter(search: string = '') {
    return await http.get<unknown, any>(
      `/indicator/GetAllIndicatorsFilter?searchTerm=${search}`
    )
  },

  async getScoreRanges(search: string = '') {
    return await http.get<unknown, any>(
      `/scorerange/GetAllScoreRangerFilter?searchTerm=${search}`
    )
  },

  async getAllCourses() {
    return await http.get<unknown, any>(`/course/getAllCourses`)
  },

  async getFormationCoursesByLevel(level: number) {
    return await http.get<unknown, any>(
      `/courseFormation/getFormationCoursesByLevel?level=${level}`
    )
  },
  // async getEducationalInstitutionByLevel(level: number) {
  //   return await http.get<unknown, any>(
  //     `/educationalInstitution/getEducationalInstitutionByLevel?level=${level}`
  //   )
  // },

  async getEducationalInstitutionByLevel(pagination: any) {
    let url = '/educationalInstitution/getEducationalInstitutionByLevel'

    if (pagination) {
      const params = new URLSearchParams()

      Object.keys(pagination).forEach(key => {
        params.append(key, pagination[key])
      })

      url += `?${params.toString()}`
    }
    return await http.get<unknown, any>(url)
  },

  async getAllKnowledgeItems(): Promise<TKnowledgeItem[]> {
    return await http.get(`/knowledgeItem/getAllKnowledgeItems`)
  },

  async getNationalities(): Promise<TNationality[]> {
    return [
      { id: 1, name: 'Afegão' },
      { id: 2, name: 'Alemão' },
      { id: 3, name: 'Americano' },
      { id: 4, name: 'Angolano' },
      { id: 5, name: 'Argentino' },
      { id: 6, name: 'Australiano' },
      { id: 7, name: 'Belga' },
      { id: 8, name: 'Boliviano' },
      { id: 9, name: 'Brasileiro' },
      { id: 10, name: 'Britânico' },
      { id: 11, name: 'Canadense' },
      { id: 12, name: 'Chileno' },
      { id: 13, name: 'Chinês' },
      { id: 14, name: 'Colombiano' },
      { id: 15, name: 'Cubano' },
      { id: 16, name: 'Dinamarquês' },
      { id: 17, name: 'Egípcio' },
      { id: 18, name: 'Equatoriano' },
      { id: 19, name: 'Espanhol' },
      { id: 20, name: 'Filipino' },
      { id: 21, name: 'Francês' },
      { id: 22, name: 'Grego' },
      { id: 23, name: 'Haitiano' },
      { id: 24, name: 'Holandês' },
      { id: 25, name: 'Hondurenho' },
      { id: 26, name: 'Húngaro' },
      { id: 27, name: 'Indiano' },
      { id: 28, name: 'Indonésio' },
      { id: 29, name: 'Iraquiano' },
      { id: 30, name: 'Iraniano' },
      { id: 31, name: 'Irlandês' },
      { id: 32, name: 'Italiano' },
      { id: 33, name: 'Jamaicano' },
      { id: 34, name: 'Japonês' },
      { id: 35, name: 'Libanês' },
      { id: 36, name: 'Malaio' },
      { id: 37, name: 'Marroquino' },
      { id: 38, name: 'Mexicano' },
      { id: 39, name: 'Moçambicano' },
      { id: 40, name: 'Nigeriano' },
      { id: 41, name: 'Norueguês' },
      { id: 42, name: 'Paquistanês' },
      { id: 43, name: 'Paraguaio' },
      { id: 44, name: 'Peruano' },
      { id: 45, name: 'Polonês' },
      { id: 46, name: 'Português' },
      { id: 47, name: 'Russo' },
      { id: 48, name: 'Sul-Africano' },
      { id: 49, name: 'Sueco' },
      { id: 50, name: 'Suíço' },
      { id: 51, name: 'Tailandês' },
      { id: 52, name: 'Turco' },
      { id: 53, name: 'Ucraniano' },
      { id: 54, name: 'Uruguaio' },
      { id: 55, name: 'Venezuelano' },
      { id: 56, name: 'Vietnamita' },
      { id: 57, name: 'Zimbabuano' }
    ]
  },

  async getGenders(): Promise<TNameValueOption[]> {
    return [
      { name: 'Masculino', value: 'M' },
      { name: 'Feminino', value: 'F' }
    ]
  },

  async getSexualOrientations(): Promise<TValueOption[]> {
    return [
      { value: 'Mulher Cisgênero' },
      { value: 'Homem Cisgênero' },
      { value: 'Mulher Transgênero' },
      { value: 'Homem Transgênero' },
      { value: 'Outros' }
    ]
  },

  async getMaritalStatuses(): Promise<TNameValueOption[]> {
    return [
      { name: 'Solteiro', value: 'S' },
      { name: 'Casado', value: 'C' },
      { name: 'Divorciado', value: 'D' },
      { name: 'Separado', value: 'P' }
    ]
  },

  async getLevels(): Promise<TLevelOption[]> {
    return [
      { id: 1, name: 'Iniciante' },
      { id: 2, name: 'Intermediário' },
      { id: 3, name: 'Avançado' }
    ]
  },

  async getLegalRelationShip(): Promise<TValueOption[]> {
    return [
      { value: 'Pai' },
      { value: 'Mãe' },
      { value: 'Avô(Ó)' },
      { value: 'Neto(A)' },
      { value: 'Irmão(Ã)' },
      { value: 'Tio(A)' },
      { value: 'Sobrinho(A)' },
      { value: 'Primo(A)' },
      { value: 'Cunhado(A)' },
      { value: 'Sogro(A)' },
      { value: 'Genro' },
      { value: 'Nora' },
      { value: 'Padrasto' },
      { value: 'Madrasta' },
      { value: 'Enteado(A)' },
      { value: 'Tutor(A)' },
      { value: 'Assistência Social' }
    ]
  },

  async getHowDidYouHearAboutISBETs(): Promise<any[]> {
    return [
      {
        id: EHowDidYouHearAboutISBETEnum.CRAS,
        value: 'Encaminhado Pelo CRAS'
      },
      {
        id: EHowDidYouHearAboutISBETEnum.CREAS,
        value: 'Encaminhado Pelo CREAS'
      },
      {
        id: EHowDidYouHearAboutISBETEnum.FRIEND,
        value: 'Indicação De Um Amigo'
      },
      {
        id: EHowDidYouHearAboutISBETEnum.SOCIALMIDIA,
        value: 'Redes Sociais'
      },
      {
        id: EHowDidYouHearAboutISBETEnum.OTHERS,
        value: 'Outros'
      }
    ]
  },

  async getSpecificSkills(pagination: any) {
    let url = '/common/GetSpecificSkills'

    if (pagination) {
      const params = new URLSearchParams()

      Object.keys(pagination).forEach(key => {
        params.append(key, pagination[key])
      })

      url += `?${params.toString()}`
    }
    return await http.get<unknown, any>(url)
  },

  async getAllNewCoursesFilter(pagination: any) {
    let url = '/newCourse/GetAllNewCoursesFilter'

    if (pagination) {
      const params = new URLSearchParams()

      Object.keys(pagination).forEach(key => {
        params.append(key, pagination[key])
      })

      url += `?${params.toString()}`
    }
    return await http.get<unknown, any>(url)
  },

  async getSocialMedias(): Promise<any[]> {
    return await http.get(`/common/GetSocialMedias`)
  }
}
