import { jwtDecode } from 'jwt-decode'
import http from '../../../core/services/api/request'
import {
  TUserForgotRequest,
  TUserLoginRequest,
  TUserLoginResponse,
  TUserNewPassordRequest
} from '../../models'
const basePath: string = 'account'

export const authService = {
  setToken(token: string, keep: boolean): void {
    keep
      ? localStorage.setItem('isbettoken', token)
      : sessionStorage.setItem('isbettoken', token)
  },

  getToken(): string | null {
    return (
      localStorage.getItem('isbettoken') || sessionStorage.getItem('isbettoken')
    )
  },

  removeToken(): void {
    localStorage.removeItem('isbettoken')
    sessionStorage.removeItem('isbettoken')
  },

  decodeToken(): void {
    const token: string | null = this.getToken()
    return token ? jwtDecode(token) : undefined
  },

  isAuthenticated(): boolean {
    return this.getToken() != null
  },

  async login(data: TUserLoginRequest) {
    return http.post<unknown, TUserLoginResponse>(
      `${basePath}/authenticate`,
      data
    )
  },

  async formAuthorizer() {
    return http.get<unknown, TUserLoginResponse>(`${basePath}/FormAuthorizer`)
  },

  async verifyCaptcha(captchaResponse: string) {
    return http.get<unknown, TUserLoginResponse>(
      `recaptcha/Verify?recaptchaResponse=${captchaResponse}`
    )
  },

  async recoverPassword(data: TUserForgotRequest) {
    return http.post(`${basePath}/recoverPassword`, data)
  },

  async resetPassword(data: TUserNewPassordRequest) {
    return http.post(`${basePath}/resetPassword`, data)
  }
}
