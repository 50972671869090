import React, { ChangeEvent, useEffect, useState } from 'react'
import { HiOutlineTrash } from 'react-icons/hi'
import { IoMdAddCircleOutline } from 'react-icons/io'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import BradCrumbs from '../../shared/components/common/breadcrumbs'
import CustomModal from '../../shared/components/common/custom-modal'
import CustomSpinner from '../../shared/components/common/custom-spinner'
import { notification } from '../../shared/services'
import { FormStyle } from '../../shared/styles/form.style'
import { CourseTable } from './components/course-table'
import { CourseStyle } from './style'
import { coursesService } from '../../shared/services/courses'
import { TCourseRequestPagination, TCoursesTableBody } from '../../core/models'

const CoursePage: React.FC = () => {
  let debounceTimer: any
  const navigate = useNavigate()
  const location = useLocation()
  if (!location.state) {
    location.state = '#bc0823'
  }
  const [search, setSearch] = useState<string>('')
  const [isloading, setLoading] = useState<boolean>(false)
  const [selectedCourseId, setSelectedCourseId] = useState<number | null>(null)
  const [showConfirmActivationModal, setShowConfirmActivationModal] =
    useState(false)

  const [pagination, setPagination] = useState<TCourseRequestPagination>({
    searchTerm: '',
    page: 1,
    pageSize: 12,
    isAsc: true,
    orderCollumnName: ''
  })

  const [courses, setCourses] = useState<TCoursesTableBody[]>([])

  const tableInfo = {
    options: {
      striped: true,
      sortable: true,
      hover: true,
      responsive: true,
      size: 'sm',
      showEdit: true,
      showDelete: false,
      skipItems: ['id'],
      clickable: [],
      backgroundColor: location.state || '##bc0823'
    },

    headers: [
      {
        id: 1,
        label: 'Id',
        sortable: true,
        isSort: true,
        originalName: 'id'
      },
      {
        id: 2,
        label: 'Nome',
        sortable: true,
        isSort: true,
        originalName: 'name'
      },
      {
        id: 3,
        label: 'Descrição',
        sortable: true,
        isSort: false,
        originalName: 'description'
      },
      {
        id: 4,
        label: 'Status',
        sortable: true,
        isSort: false,
        originalName: 'active'
      },
      {
        id: 9,
        label: 'Ações',
        sortable: false,
        isSort: false
      }
    ]
  }

  const handlerSetSort = (value: any) => {
    setPagination(prevData => ({
      ...prevData,
      orderCollumnName: value
        ? value.originalName.charAt(0).toUpperCase() +
          value.originalName.slice(1)
        : 'Id',
      isAsc: value ? value.isSort : true,
      page: 1
    }))
  }

  const handlerScroll = (event: any) => {
    setPagination(prevData => ({
      ...prevData,
      page: (prevData.page += 1)
    }))
  }

  const handleEdit = (value: any) => {
    navigate(`/course/course-record/${value.id}`)
  }

  const handleCloseConfirmActivationModal = () => {
    setShowConfirmActivationModal(false)
    setSelectedCourseId(null)
  }

  const handlerConfirmEditModal = (event: any) => {
    setShowConfirmActivationModal(true)
    setSelectedCourseId(event.id)
  }

  const handlerConfirmActivation = async () => {
    try {
      if (selectedCourseId) {
        const course = courses.find(
          (i: TCoursesTableBody) => i.id === selectedCourseId
        )
        if (!course) return
        setLoading(true)
        const result = await coursesService.activateCourse({
          courseId: selectedCourseId,
          active: !course.active
        })
        notification.success('Sucesso.', result.message)
        handleCloseConfirmActivationModal()
        handlerLoadCourses()
      }
    } finally {
      setLoading(false)
    }
  }

  const handleInputSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value || ''
    setSearch(value)
    if (value.length === 0 || value.length > 2) debounceSearch(value)
  }

  const debounceSearch = (value: string) => {
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => {
      setPagination(prevData => ({
        ...prevData,
        searchTerm: value
      }))
    }, 500)
  }

  const handlerLoadCourses = async (): Promise<void> => {
    try {
      setLoading(true)
      const courses = await coursesService.getAllNewCourses(pagination)
      if (pagination.page > 1) {
        setCourses(prevCourses => [...prevCourses, ...courses])
      } else {
        setCourses(courses)
      }
    } catch {
    } finally {
      setLoading(false)
    }
  }

  const getCourseState = () => {
    const course = courses.find(
      (i: TCoursesTableBody) => i.id === selectedCourseId
    )
    return course!.active ? 'Inativar' : 'Ativar'
  }

  useEffect(() => {
    handlerLoadCourses()
  }, [pagination])

  return (
    <CourseStyle.Container className="w-100">
      <BradCrumbs
        names={['Cursos']}
        paths={[]}
        disabled={false}
        showArrow={false}
        backGroundColor={location.state || '##bc0823'}
      />
      <div style={{ paddingLeft: '2.6%', paddingRight: '2.6%' }}>
        <CourseStyle.HeaderContent className="row mt-4">
          <CourseStyle.PageTitle className="col-1 ">
            Cursos
          </CourseStyle.PageTitle>
          <div className="col"></div>
          <div className="col"></div>
          <div className="col"></div>
          <div className="col"></div>
          <div className="col">
            <CourseStyle.NewOportunityBtn
              backgroundColor={location.state || '##bc0823'}
              className="px-3"
            >
              <Link
                className="dropdown-item"
                to="/course/course-record"
                state={location.state || '##bc0823'}
              >
                <IoMdAddCircleOutline size={25} className="me-2" /> Cadastrar
                Novo Curso
              </Link>
            </CourseStyle.NewOportunityBtn>
          </div>
        </CourseStyle.HeaderContent>
        {showConfirmActivationModal && (
          <CustomModal
            show={showConfirmActivationModal}
            onClose={handleCloseConfirmActivationModal}
            onSave={handlerConfirmActivation}
            title="Confirmação"
            icon={<HiOutlineTrash size={20} />}
            state={location.state || '##bc0823'}
            onlyCloseBtn={false}
            buttonTitle={'Continuar'}
          >
            <h5>Tem certeza que deseja {getCourseState()}?</h5>
          </CustomModal>
        )}
        <CourseStyle.FilterContent className="mt-3 ">
          <div className="w-100 " style={{ position: 'relative' }}>
            <FormStyle.SearchIcon />
            <FormStyle.SearchInput
              type="search"
              placeholder="Busca"
              name="search"
              value={search}
              onChange={handleInputSearchChange}
            />
          </div>
        </CourseStyle.FilterContent>
        <CustomSpinner state={location.state || '##bc0823'} show={isloading} />
        <div
          className="mt-3 w-100"
          style={{
            overflowX: 'hidden',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            borderTop: '1px solid #ccc'
          }}
        >
          <CourseTable
            headers={tableInfo.headers}
            body={courses}
            options={tableInfo.options}
            sortHeader={handlerSetSort}
            scroll={handlerScroll}
            editCourse={handleEdit}
            activation={handlerConfirmEditModal}
          ></CourseTable>
        </div>
      </div>
    </CourseStyle.Container>
  )
}

export default CoursePage
