import React, { useEffect, useState } from 'react'
import { IoIosLogOut } from 'react-icons/io'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { Container, RightConner } from './style'
import { FaRegUser } from 'react-icons/fa'
import './style.css'
import { TNavProps } from '../../../../core/models'
import logo from '../../../../assets/images/logo.png'
import { authService } from '../../../../core/services'
import { useLocation, useNavigate } from 'react-router-dom'
import { RightTopConner, RightTopConnerFixed } from '../right-top-conner'

const BradCrumbs = (props: TNavProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const backGroundColor = props.backGroundColor
  const [isCandidateSelfRecord, setIsCandidateSelfRecord] = useState<
    boolean | null
  >(null)
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const logOut = () => {
    authService.removeToken()
    navigate('/')
  }

  const handlerCheckDevice = () => {
    setIsMobile(window.innerWidth >= 768)
  }

  useEffect(() => {
    handlerCheckDevice()
    setIsCandidateSelfRecord(location.pathname === '/cadastro-candidato')
  }, [location.pathname])

  return (
    <Container>
      <img
        style={{ width: '60px', marginLeft: '30px', marginRight: '30px' }}
        src={logo}
        alt="logo"
      />
      {!isCandidateSelfRecord ? (
        <>
          {isMobile &&
            props.names.map((name: any, index: number) => (
              <React.Fragment key={index}>
                <span>{name}</span>
                <MdKeyboardArrowRight
                  className="mx-1"
                  size={17}
                  key={`arrow-${index}`}
                />
              </React.Fragment>
            ))}

          <RightConner>
            <RightTopConner style={{ backgroundColor: backGroundColor }} />
            <div className="actions">
              <button
                className="btn btn-access"
                style={{ color: 'white', marginRight: '5px' }}
              >
                <FaRegUser />
              </button>
              <button
                className="btn btn-access"
                style={{ color: 'white' }}
                onClick={logOut}
              >
                <IoIosLogOut />
              </button>
            </div>
          </RightConner>
        </>
      ) : (
        <RightTopConnerFixed style={{ backgroundColor: backGroundColor }} />
      )}
    </Container>
  )
}
export default BradCrumbs
