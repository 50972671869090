import React, { useEffect, useRef, useState } from 'react'
import { CourseTableStyle } from './style'
import './style.css'
import { IoIosArrowDown, IoMdClose } from 'react-icons/io'
import { MdOutlineEdit } from 'react-icons/md'
import { FaCheck } from 'react-icons/fa6'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { Tooltip } from 'react-bootstrap'
import { CourseTableProps } from '../../../../core/models'
export const CourseTable: React.FC<CourseTableProps> = ({
  headers,
  body,
  options,
  sortHeader,
  scroll,
  editCourse,
  activation
}: any) => {
  const [tableHeaders, setTableHeaders] = useState(headers)
  const prevScrollY = useRef<number>(0)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = document.querySelector('.table-wrapper')?.scrollTop
      if (currentScrollY == null) return
      const isScrollingDown = currentScrollY > prevScrollY.current
      prevScrollY.current = currentScrollY

      const element = document.querySelector('.table-wrapper')
      if (element) {
        const isScrolledToBottom =
          element.scrollHeight - element.clientHeight <= currentScrollY + 1
        if (isScrollingDown && isScrolledToBottom) {
          scroll()
        }
      }
    }

    document
      .querySelector('.table-wrapper')
      ?.addEventListener('scroll', handleScroll)

    return () => {
      document
        .querySelector('.table-wrapper')
        ?.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const scrollUp = () => {
    const element = document.querySelector('.table-wrapper')
    if (!element) return
    element.scrollBy({ top: -50, behavior: 'smooth' })
  }

  const sortByClick = (clickedHeader: any) => {
    if (clickedHeader.sortable) {
      const updatedHeaders = tableHeaders.map((header: any) => ({
        ...header,
        isSort: header.id === clickedHeader.id ? !header.isSort : false
      }))
      setTableHeaders(updatedHeaders)
      sortHeader(clickedHeader)
      scrollUp()
    }
  }

  return (
    <div className="table-wrapper" style={{ overflowX: 'auto' }}>
      <CourseTableStyle.CustomTable
        striped={options?.striped}
        className="w-100"
      >
        <thead
          style={{
            height: '60px',
            position: 'sticky',
            top: '0',
            zIndex: '1'
          }}
        >
          <tr>
            {tableHeaders.map((header: any) =>
              options.skipItems.length &&
              !options.skipItems.includes(header.label.toLowerCase()) ? (
                <th
                  style={{ paddingBottom: '17px', background: '#FCFCFC' }}
                  key={header.id}
                  onClick={() => sortByClick(header)}
                >
                  {header.label}
                  {header.sortable ? (
                    <IoIosArrowDown
                      className={header.isSort ? 'rotate' : 'reverse'}
                    />
                  ) : null}
                </th>
              ) : null
            )}
          </tr>
        </thead>
        <tbody>
          {body.map((row: any) => (
            <tr key={row.id}>
              {Object.keys(row)
                .filter(key => !options.skipItems.includes(key))
                .map((key, index) => (
                  <td
                    style={{
                      fontWeight: '600',
                      fontSize: '14px',
                      paddingTop: '14px',
                      width: index === 2 ? '135px' : 'auto'
                    }}
                    key={key}
                  >
                    <span
                      style={{
                        whiteSpace: index === 1 ? 'nowrap' : '',
                        color:
                          key === 'active'
                            ? row[key]
                              ? 'green'
                              : 'red'
                            : 'inherit'
                      }}
                    >
                      {key !== 'active'
                        ? row[key]
                        : row[key]
                        ? 'Ativo'
                        : 'Inativo'}
                    </span>
                  </td>
                ))}
              <td style={{ paddingTop: '14px' }}>
                <div className="d-flex">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="button-tooltip-2">Editar</Tooltip>}
                  >
                    <CourseTableStyle.CustomMiniButton
                      backgroundColor={options.backgroundColor}
                      onClick={() => editCourse(row)}
                    >
                      <MdOutlineEdit />
                    </CourseTableStyle.CustomMiniButton>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        {row.active ? 'Desativar' : 'Ativar'}
                      </Tooltip>
                    }
                  >
                    <CourseTableStyle.CustomMiniButton
                      backgroundColor={options.backgroundColor}
                      onClick={() => activation(row)}
                    >
                      {row.active ? <IoMdClose /> : <FaCheck />}
                    </CourseTableStyle.CustomMiniButton>
                  </OverlayTrigger>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </CourseTableStyle.CustomTable>
    </div>
  )
}
